.f32 {position:fixed;right:2vw;}
.f32 .flag{height:32px;width:32px;line-height:32px;background:url(flags32.png) no-repeat;}
.f32 ._African_Union{background-position:0 -32px;}
.f32 ._Arab_League{background-position:0 -64px;}
.f32 ._ASEAN{background-position:0 -96px;}
.f32 ._CARICOM{background-position:0 -128px;}
.f32 ._CIS{background-position:0 -160px;}
.f32 ._Commonwealth{background-position:0 -192px;}
.f32 ._England{background-position:0 -224px;}
.f32 ._European_Union, .f32 .eu{background-position:0 -256px;}
.f32 ._Islamic_Conference{background-position:0 -288px;}
.f32 ._Kosovo{background-position:0 -320px;}
.f32 ._NATO{background-position:0 -352px;}
.f32 ._Northern_Cyprus{background-position:0 -384px;}
.f32 ._Northern_Ireland{background-position:0 -416px;}
.f32 ._Olimpic_Movement{background-position:0 -448px;}
.f32 ._OPEC{background-position:0 -480px;}
.f32 ._Red_Cross{background-position:0 -512px;}
.f32 ._Scotland{background-position:0 -544px;}
.f32 ._Somaliland{background-position:0 -576px;}
.f32 ._Tibet{background-position:0 -608px;}
.f32 ._United_Nations{background-position:0 -640px;}
.f32 ._Wales{background-position:0 -672px;}
.f32 .ad, .f32 .and {background-position: 0 -704px;}
.f32 .ae, .f32 .are {background-position: 0 -736px;}
.f32 .af, .f32 .afg {background-position: 0 -768px;}
.f32 .ag, .f32 .atg {background-position: 0 -800px;}
.f32 .ai, .f32 .aia {background-position: 0 -832px;}
.f32 .al, .f32 .alb {background-position: 0 -864px;}
.f32 .am, .f32 .arm {background-position: 0 -896px;}
.f32 .ao, .f32 .ago {background-position: 0 -928px;}
.f32 .aq, .f32 .ata {background-position: 0 -960px;}
.f32 .ar, .f32 .arg {background-position: 0 -992px;}
.f32 .as, .f32 .asm {background-position: 0 -1024px;}
.f32 .at, .f32 .aut {background-position: 0 -1056px;}
.f32 .au, .f32 .aus {background-position: 0 -1088px;}
.f32 .aw, .f32 .abw {background-position: 0 -1120px;}
.f32 .ax, .f32 .ala {background-position: 0 -1152px;}
.f32 .az, .f32 .aze {background-position: 0 -1184px;}
.f32 .ba, .f32 .bih {background-position: 0 -1216px;}
.f32 .bb, .f32 .brb {background-position: 0 -1248px;}
.f32 .bd, .f32 .bgd {background-position: 0 -1280px;}
.f32 .be, .f32 .bel {background-position: 0 -1312px;}
.f32 .bf, .f32 .bfa {background-position: 0 -1344px;}
.f32 .bg, .f32 .bgr {background-position: 0 -1376px;}
.f32 .bh, .f32 .bhr {background-position: 0 -1408px;}
.f32 .bi, .f32 .bdi {background-position: 0 -1440px;}
.f32 .bj, .f32 .ben {background-position: 0 -1472px;}
.f32 .bm, .f32 .bmu {background-position: 0 -1504px;}
.f32 .bn, .f32 .brn {background-position: 0 -1536px;}
.f32 .bo, .f32 .bol {background-position: 0 -1568px;}
.f32 .br, .f32 .bra {background-position: 0 -1600px;}
.f32 .bs, .f32 .bhs {background-position: 0 -1632px;}
.f32 .bt, .f32 .btn {background-position: 0 -1664px;}
.f32 .bw, .f32 .bwa {background-position: 0 -1696px;}
.f32 .by, .f32 .blr {background-position: 0 -1728px;}
.f32 .bz, .f32 .blz {background-position: 0 -1760px;}
.f32 .ca, .f32 .can {background-position: 0 -1792px;}
.f32 .cd, .f32 .cod {background-position: 0 -1824px;}
.f32 .cf, .f32 .rca {background-position: 0 -1856px;}
.f32 .cg, .f32 .cog {background-position: 0 -1888px;}
.f32 .ch, .f32 .che {background-position: 0 -1920px;}
.f32 .ci, .f32 .civ {background-position: 0 -1952px;}
.f32 .ck, .f32 .cok {background-position: 0 -1984px;}
.f32 .cl, .f32 .chl {background-position: 0 -2016px;}
.f32 .cm, .f32 .cmr {background-position: 0 -2048px;}
.f32 .cn, .f32 .chn {background-position: 0 -2080px;}
.f32 .co, .f32 .col {background-position: 0 -2112px;}
.f32 .cr, .f32 .cri {background-position: 0 -2144px;}
.f32 .cu, .f32 .cub {background-position: 0 -2176px;}
.f32 .cv, .f32 .cpv {background-position: 0 -2208px;}
.f32 .cy, .f32 .cyp {background-position: 0 -2240px;}
.f32 .cz, .f32 .cze {background-position: 0 -2272px;}
.f32 .de, .f32 .deu {background-position: 0 -2304px;}
.f32 .dj, .f32 .dji {background-position: 0 -2336px;}
.f32 .dk, .f32 .dnk {background-position: 0 -2368px;}
.f32 .dm, .f32 .dma {background-position: 0 -2400px;}
.f32 .do, .f32 .dom {background-position: 0 -2432px;}
.f32 .dz, .f32 .dza {background-position: 0 -2464px;}
.f32 .ec, .f32 .ecu {background-position: 0 -2496px;}
.f32 .ee, .f32 .est {background-position: 0 -2528px;}
.f32 .eg, .f32 .egy {background-position: 0 -2560px;}
.f32 .eh, .f32 .esh {background-position: 0 -2592px;}
.f32 .er, .f32 .eri {background-position: 0 -2624px;}
.f32 .es, .f32 .esp {background-position: 0 -2656px;}
.f32 .et, .f32 .eth {background-position: 0 -2688px;}
.f32 .fi, .f32 .fin {background-position: 0 -2720px;}
.f32 .fj, .f32 .fji {background-position: 0 -2752px;}
.f32 .fm, .f32 .fsm {background-position: 0 -2784px;}
.f32 .fo, .f32 .fro {background-position: 0 -2816px;}
.f32 .fr, .f32 .fra {background-position: 0 -2848px;}
.f32 .bl, .f32 .blm {background-position: 0 -2848px;}
.f32 .cp, .f32 .cpt {background-position: 0 -2848px;}
.f32 .mf, .f32 .maf {background-position: 0 -2848px;}
.f32 .yt, .f32 .myt {background-position: 0 -2848px;}
.f32 .ga, .f32 .gab {background-position: 0 -2880px;}
.f32 .gb, .f32 .gbr {background-position: 0 -2912px;}
.f32 .sh, .f32 .shn {background-position: 0 -2912px;}
.f32 .gd, .f32 .grd {background-position: 0 -2944px;}
.f32 .ge, .f32 .geo {background-position: 0 -2976px;}
.f32 .gg, .f32 .ggy {background-position: 0 -3008px;}
.f32 .gh, .f32 .gha {background-position: 0 -3040px;}
.f32 .gi, .f32 .gib {background-position: 0 -3072px;}
.f32 .gl, .f32 .grl {background-position: 0 -3104px;}
.f32 .gm, .f32 .gmb {background-position: 0 -3136px;}
.f32 .gn, .f32 .gin {background-position: 0 -3168px;}
.f32 .gp, .f32 .glp {background-position: 0 -3200px;}
.f32 .gq, .f32 .gnq {background-position: 0 -3232px;}
.f32 .gr, .f32 .grc {background-position: 0 -3264px;}
.f32 .gt, .f32 .gtm {background-position: 0 -3296px;}
.f32 .gu, .f32 .gum {background-position: 0 -3328px;}
.f32 .gw, .f32 .gnb {background-position: 0 -3360px;}
.f32 .gy, .f32 .guy {background-position: 0 -3392px;}
.f32 .hk, .f32 .hkg {background-position: 0 -3424px;}
.f32 .hn, .f32 .hnd {background-position: 0 -3456px;}
.f32 .hr, .f32 .hrv {background-position: 0 -3488px;}
.f32 .ht, .f32 .hti {background-position: 0 -3520px;}
.f32 .hu, .f32 .hun {background-position: 0 -3552px;}
.f32 .id, .f32 .idn {background-position: 0 -3584px;}
.f32 .mc, .f32 .mco {background-position: 0 -3584px;}
.f32 .ie {background-position: 0 -3616px;}
.f32 .il, .f32 .isr {background-position: 0 -3648px;}
.f32 .im, .f32 .imn {background-position: 0 -3680px;}
.f32 .in, .f32 .ind {background-position: 0 -3712px;}
.f32 .iq, .f32 .irq {background-position: 0 -3744px;}
.f32 .ir, .f32 .irn {background-position: 0 -3776px;}
.f32 .is, .f32 .isl {background-position: 0 -3808px;}
.f32 .it, .f32 .ita {background-position: 0 -3840px;}
.f32 .je, .f32 .jey {background-position: 0 -3872px;}
.f32 .jm, .f32 .jam {background-position: 0 -3904px;}
.f32 .jo, .f32 .jor {background-position: 0 -3936px;}
.f32 .jp, .f32 .jpn {background-position: 0 -3968px;}
.f32 .ke, .f32 .ken {background-position: 0 -4000px;}
.f32 .kg, .f32 .kgz {background-position: 0 -4032px;}
.f32 .kh, .f32 .khm {background-position: 0 -4064px;}
.f32 .ki, .f32 .kir {background-position: 0 -4096px;}
.f32 .km, .f32 .com {background-position: 0 -4128px;}
.f32 .kn, .f32 .kna {background-position: 0 -4160px;}
.f32 .kp, .f32 .prk {background-position: 0 -4192px;}
.f32 .kr, .f32 .kor {background-position: 0 -4224px;}
.f32 .kw, .f32 .kwt {background-position: 0 -4256px;}
.f32 .ky, .f32 .cym {background-position: 0 -4288px;}
.f32 .kz, .f32 .kaz {background-position: 0 -4320px;}
.f32 .la, .f32 .lao {background-position: 0 -4352px;}
.f32 .lb, .f32 .lbn {background-position: 0 -4384px;}
.f32 .lc, .f32 .lca {background-position: 0 -4416px;}
.f32 .li, .f32 .lie {background-position: 0 -4448px;}
.f32 .lk, .f32 .lka {background-position: 0 -4480px;}
.f32 .lr, .f32 .lbr {background-position: 0 -4512px;}
.f32 .ls, .f32 .lso {background-position: 0 -4544px;}
.f32 .lt, .f32 .ltu {background-position: 0 -4576px;}
.f32 .lu, .f32 .lux {background-position: 0 -4608px;}
.f32 .lv, .f32 .lva {background-position: 0 -4640px;}
.f32 .ly, .f32 .lby {background-position: 0 -4672px;}
.f32 .ma, .f32 .mar {background-position: 0 -4704px;}
.f32 .md, .f32 .mda {background-position: 0 -4736px;}
.f32 .me, .f32 .mne {background-position: 0 -4768px;}
.f32 .mg, .f32 .mdg {background-position: 0 -4800px;}
.f32 .mh, .f32 .mhl {background-position: 0 -4832px;}
.f32 .mk, .f32 .mkd {background-position: 0 -4864px;}
.f32 .ml, .f32 .mli {background-position: 0 -4896px;}
.f32 .mm, .f32 .mmr {background-position: 0 -4928px;}
.f32 .mn, .f32 .mng {background-position: 0 -4960px;}
.f32 .mo, .f32 .mac {background-position: 0 -4992px;}
.f32 .mq, .f32 .mtq {background-position: 0 -5024px;}
.f32 .mr, .f32 .mrt {background-position: 0 -5056px;}
.f32 .ms, .f32 .msr {background-position: 0 -5088px;}
.f32 .mt, .f32 .mlt {background-position: 0 -5120px;}
.f32 .mu, .f32 .mus {background-position: 0 -5152px;}
.f32 .mv, .f32 .mdv {background-position: 0 -5184px;}
.f32 .mw, .f32 .mwi {background-position: 0 -5216px;}
.f32 .mx, .f32 .mex {background-position: 0 -5248px;}
.f32 .my, .f32 .mys {background-position: 0 -5280px;}
.f32 .mz, .f32 .moz {background-position: 0 -5312px;}
.f32 .na, .f32 .nam {background-position: 0 -5344px;}
.f32 .nc, .f32 .ncl {background-position: 0 -5376px;}
.f32 .ne, .f32 .ner {background-position: 0 -5408px;}
.f32 .ng, .f32 .nga {background-position: 0 -5440px;}
.f32 .ni, .f32 .nic {background-position: 0 -5472px;}
.f32 .nl, .f32 .nld {background-position: 0 -5504px;}
.f32 .nl{background-position:0 -5504px;}  .f32 .bq{background-position:0 -5504px;}.f32 .no{background-position:0 -5536px;}  .f32 .bv, .f32 .nq, .f32 .sj{background-position:0 -5536px;}
.f32 .bq, .f32 .bes {background-position: 0 -5504px;}
.f32 .no, .f32 .nor {background-position: 0 -5536px;}
.f32 .bv, .f32 .bvt {background-position: 0 -5536px;}
.f32 .nq, .f32 .atn {background-position: 0 -5536px;}
.f32 .sj, .f32 .sjm {background-position: 0 -5536px;}
.f32 .np, .f32 .npl {background-position: 0 -5568px;}
.f32 .nr, .f32 .nru {background-position: 0 -5600px;}
.f32 .nz, .f32 .nzl {background-position: 0 -5632px;}
.f32 .om, .f32 .omn {background-position: 0 -5664px;}
.f32 .pa, .f32 .pan {background-position: 0 -5696px;}
.f32 .pe, .f32 .per {background-position: 0 -5728px;}
.f32 .pf, .f32 .pyf {background-position: 0 -5760px;}
.f32 .pg, .f32 .png {background-position: 0 -5792px;}
.f32 .ph, .f32 .phl {background-position: 0 -5824px;}
.f32 .pk, .f32 .pak {background-position: 0 -5856px;}
.f32 .pl, .f32 .pol {background-position: 0 -5888px;}
.f32 .pr, .f32 .pri {background-position: 0 -5920px;}
.f32 .ps, .f32 .pse {background-position: 0 -5952px;}
.f32 .pt, .f32 .prt {background-position: 0 -5984px;}
.f32 .pw, .f32 .plw {background-position: 0 -6016px;}
.f32 .py, .f32 .pry {background-position: 0 -6048px;}
.f32 .qa, .f32 .qat {background-position: 0 -6080px;}
.f32 .re, .f32 .reu {background-position: 0 -6112px;}
.f32 .ro, .f32 .rou {background-position: 0 -6144px;}
.f32 .rs, .f32 .srb {background-position: 0 -6176px;}
.f32 .ru, .f32 .rus {background-position: 0 -6208px;}
.f32 .rw, .f32 .rwa {background-position: 0 -6240px;}
.f32 .sa, .f32 .sau {background-position: 0 -6272px;}
.f32 .sb, .f32 .slb {background-position: 0 -6304px;}
.f32 .sc, .f32 .syc {background-position: 0 -6336px;}
.f32 .sd, .f32 .sdn {background-position: 0 -6368px;}
.f32 .se, .f32 .swe {background-position: 0 -6400px;}
.f32 .sg, .f32 .sgp {background-position: 0 -6432px;}
.f32 .si, .f32 .svn {background-position: 0 -6464px;}
.f32 .sk, .f32 .svk {background-position: 0 -6496px;}
.f32 .sl, .f32 .sle {background-position: 0 -6528px;}
.f32 .sm, .f32 .smr {background-position: 0 -6560px;}
.f32 .sn, .f32 .sen {background-position: 0 -6592px;}
.f32 .so, .f32 .som {background-position: 0 -6624px;}
.f32 .sr, .f32 .sur {background-position: 0 -6656px;}
.f32 .st, .f32 .stp {background-position: 0 -6688px;}
.f32 .sv, .f32 .slv {background-position: 0 -6720px;}
.f32 .sy, .f32 .syr {background-position: 0 -6752px;}
.f32 .sz, .f32 .swz {background-position: 0 -6784px;}
.f32 .tc, .f32 .tca {background-position: 0 -6816px;}
.f32 .td, .f32 .tcd {background-position: 0 -6848px;}
.f32 .tg, .f32 .tgo {background-position: 0 -6880px;}
.f32 .th, .f32 .tha {background-position: 0 -6912px;}
.f32 .tj, .f32 .tjk {background-position: 0 -6944px;}
.f32 .tl, .f32 .tls {background-position: 0 -6976px;}
.f32 .tm, .f32 .tkm {background-position: 0 -7008px;}
.f32 .tn, .f32 .tun {background-position: 0 -7040px;}
.f32 .to, .f32 .ton {background-position: 0 -7072px;}
.f32 .tr, .f32 .tur {background-position: 0 -7104px;}
.f32 .tt, .f32 .tto {background-position: 0 -7136px;}
.f32 .tv, .f32 .tuv {background-position: 0 -7168px;}
.f32 .tw, .f32 .twn {background-position: 0 -7200px;}
.f32 .tz, .f32 .tza {background-position: 0 -7232px;}
.f32 .ua, .f32 .ukr {background-position: 0 -7264px;}
.f32 .ug, .f32 .uga {background-position: 0 -7296px;}
.f32 .us, .f32 .usa {background-position: 0 -7328px;}
.f32 .uy, .f32 .ury {background-position: 0 -7360px;}
.f32 .uz, .f32 .uzb {background-position: 0 -7392px;}
.f32 .va, .f32 .vat {background-position: 0 -7424px;}
.f32 .vc, .f32 .vct {background-position: 0 -7456px;}
.f32 .ve, .f32 .ven {background-position: 0 -7488px;}
.f32 .vg, .f32 .vgb {background-position: 0 -7520px;}
.f32 .vi, .f32 .vir {background-position: 0 -7552px;}
.f32 .vn, .f32 .vnm {background-position: 0 -7584px;}
.f32 .vu, .f32 .vut {background-position: 0 -7616px;}
.f32 .ws, .f32 .wsm {background-position: 0 -7648px;}
.f32 .ye, .f32 .yem {background-position: 0 -7680px;}
.f32 .za, .f32 .zaf {background-position: 0 -7712px;}
.f32 .zm, .f32 .zmb {background-position: 0 -7744px;}
.f32 .zw, .f32 .zwe {background-position: 0 -7776px;}
.f32 .sx, .f32 .sxm {background-position: 0 -7808px;}
.f32 .cw, .f32 .cuw {background-position: 0 -7840px;}
.f32 .ss, .f32 .ssd {background-position: 0 -7872px;}
.f32 .nu, .f32 .niu {background-position: 0 -7904px;}
