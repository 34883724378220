@import "bootstrap/scss/bootstrap";
@import "vanillajs-datepicker/sass/datepicker-bs5.scss";

@font-face {
  font-family: "Gilroy";
  font-weight: 800;
  font-style: normal;
  src: url("https://cdn.jsdelivr.net/gh/repalash/gilroy-free-webfont@fonts/Gilroy-Extrabold.eot"),
    local("Gilroy Extrabold"), local("Gilroy-Extrabold"),
    url("https://cdn.jsdelivr.net/gh/repalash/gilroy-free-webfont@fonts/Gilroy-Extrabold.eot?#iefix")
      format("embedded-opentype"),
    url("https://cdn.jsdelivr.net/gh/repalash/gilroy-free-webfont@fonts/Gilroy-Extrabold.woff")
      format("woff"),
    url("https://cdn.jsdelivr.net/gh/repalash/gilroy-free-webfont@fonts/Gilroy-Extrabold.ttf")
      format("truetype");
}

html,
body {
  height: 100vh;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  font-family: Gilroy, sans-serif;
  margin: 0;
}

body {
  background-color: #e83b4c;
}

#main {
  background-color: #ffffff;
  margin: auto;
  padding-bottom: 0.5%;
  padding-top: 0.5%;
  width: 90%;
}

.colums {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 3vh;
}

.colums div {
  width: 48%;
}
.button1,
.button2,
.button3,
.button4,
.capturebutton {
  background-color: #e83b4c;
  border: none;
  color: #fff;
  display: block;
  font-size: 2vw;
  font-weight: 600;
  margin: auto;
  text-transform: uppercase;
  border-radius: 15px;
  box-shadow: 0 3px #999;
  transition: transform 1s ease-in;
}

.button1:active,
.button2:active,
.button3:active,
.button4:active,
.capturebutton:active {
  background-color: #a1112c;
  box-shadow: 0 1px #666;
  transform: translateY(4px);
}

.button1 {
  height: 13vw;
  width: 13vw;
}

.button2 {
  height: 10vw;
  width: 90%;
}

.button3,
.capturebutton {
  height: 5vw;
  width: 25%;
}

.button4 {
min-height: 5vw;
  min-width: 50%;
margin: auto;
}

.question {
  display: block;
  font-size: 3vw;
  font-weight: 600;
  margin: auto;
  padding: 13px;
  text-align: center;
  text-transform: uppercase;
}

footer {
  font-size: 0.5vw;
  margin: 0 auto;
  margin-top: 5vh;
  text-align: center;
}

img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  max-height: 100vh;
}

.img {
  width: calc(100% - 108px);
  max-width: 358px;
}

#xsales {
  border: 2px solid #000;
  color: #000;
  cursor: pointer;
  margin: 20px;
  padding: 15px;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 99;
}

button,
.toggle input {
  cursor: pointer;
}

.loadingImage {
  left: 50%;
  margin: -75px;
  position: fixed;
  top: 50%;
  color: white;
}

#loadingText {
  color: white;
}

#loading-image {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #000000b3;
  z-index: 9999;
}

.left:after {
  color: #000;
  content: "\2190";
  display: block;
  font-size: 5vw;
  font-weight: 700;
}

.left {
  display: inline-block;
}

.toggle {
  align-items: stretch;
  display: flex;
  flex-flow: row nowrap;
  font-size: 0;
  justify-content: center;
  margin-bottom: 1.5rem;
}

.toggle input {
  height: 0;
  left: -9999px;
  position: absolute;
  width: 0;
}

.toggle input + label {
  background-color: #fff;
  border: solid 1px #000;
  box-shadow: 0 0 0 #ffffff;
  box-sizing: border-box;
  color: #000;
  display: inline-block;
  font-size: 1rem;
  font-weight: 600;
  line-height: 140%;
  margin: 0;
  padding: 0.75rem 2rem;
  position: relative;
  text-align: center;
  transition: 0.5s;
}

.toggle input + label:first-of-type {
  border-radius: 6px 0 0 6px;
  border-right: none;
}

.toggle input + label:last-of-type {
  border-left: none;
  border-radius: 0 6px 6px 0;
}

.toggle input:hover + label {
  border-color: #213140;
}

.toggle input:checked + label {
  background-color: #fae80a;
  border-color: #000;
  box-shadow: 0 0 10px #66b3fb80;
  color: #000;
  z-index: 1;
}

.toggle input:focus + label {
  outline: dotted 1px #ccc;
  outline-offset: 0.45rem;
}
#bodysales {
  background-color: #fff;
}

.banner,
.check,
.title,
.x,
footer {
  text-align: center;
}

.banner,
table {
  position: relative;
  white-space: nowrap;
}

footer {
  font-size: 50%;
  margin: 10vh auto 0;
}

.page {
  margin: 2vw;
}

span {
  color: #000;
  font: 90%/2 Gilroy, sans-serif;
  line-height: 1;
}

.page {
  background: #fff;
  border: 2px outset #000;
  height: auto;
  max-width: 96%;
}

.page p {
  color: #ee3124;
  font: 90%/2 Gilroy, sans-serif;
  line-height: 1;
  margin: 0;
}

a {
  color: inherit;
  text-decoration: inherit;
}

.myButton {
  display: inline-block;
  width: 95%;
  color: #000;
  background-color: #fae80a;
  border: 1px solid #000;
  border-radius: 3px;
  box-shadow: inset 0 1px 0 0 #cf866c;
  cursor: pointer;
  font-family: Gilroy;
  font-size: calc(14px + 0.8vw);
  padding: 6px 24px;
  text-decoration: none;
  text-shadow: 0 1px 0 #854629;
  text-align: center;
}

.myButton:hover {
  background: linear-gradient(to bottom, #bc3315 5%, #d0451b 100%);
  background-color: #bc3315;
}

.page h2 {
  background-image: linear-gradient(to right, #fae80a 40%, #fff 100%);
}

.gradient1,
.gradientrd,
.gradient2,
.gradient3 {
  background-color: #fff;
}

.gradient4 {
  background-image: linear-gradient(to right, #fae80a 1%, #fff 25%);
}

.myButton:active {
  position: relative;
  top: 1px;
}

.banner,
table {
  position: relative;
  white-space: nowrap;
}

.banner {
  transform: rotate(180deg);
  color: red;
  font-size: 2.5vw;
  font-style: italic;
  font-weight: 700;
  overflow: visible;
  padding: 2vw;
  text-transform: uppercase;
  writing-mode: vertical-rl;
}

table.tg {
  background-color: #fff;
  display: table;
  width: 100%;
}

.tg td {
  border-color: #000;
  border-style: solid;
  border-width: 1px;
  font-size: 1.25vw;
  overflow: hidden;
  word-break: normal;
}

.tg th {
  border-width: 0;
}

.tg {
  border-collapse: collapse;
  border-color: #000;
  border-spacing: 0;
  text-align: left;
  vertical-align: top;
}

.check,
.checkult,
.x {
  margin: 0;
  padding: 0;
  text-align: center;
}

.check,
.checkult {
  color: green;
}

.checkult {
  text-shadow: 0 2px, 2px 0, 2px 2px;
}

.x {
  color: red;
}

.page h2,
.plusminus,
.title {
  color: black;
  margin-left: 0;
  margin-right: 0;
}

.title {
  font-size: 2vw;
  font-family: Gilroy, sans-serif;
  font-weight: 700;
  vertical-align: bottom;
}

.ach {
  font-size: 1.25vw;
  font-family: Gilroy, sans-serif;
  text-align: center;
}

.page h2 {
  font-size: 1.75vw;
  font-weight: 600;
  font-family: Gilroy, sans-serif;
  padding-left: 20px;
  width: 90%;
}

.hidden > div {
  display: none;
}

.visible > div {
  display: block;
}

.plusminus2 {
  background-color: #fae80a;
  border: 1px solid #000;
  color: #000;
  display: flex;
  font-size: 3vh;
  font-weight: bolder;
  justify-content: center;
  padding: 1vh;
  transition: 2s ease-in-out, opacity 2.5s ease-in-out;
}
.ribbon {
  box-shadow: #0000004a;
  background: #d64b4b;
  background: linear-gradient(to bottom, #d64b4b, #ab2c2c);
  font-size: auto !important;
  margin: auto;
  position: relative;
  text-align: center;
  width: fit-content;
}
.ribbon {
  -webkit-box-shadow: #0000004a; /* In CSS, Last Defined Property is applied */
  background: -webkit-linear-gradient(top, #d64b4b, #ab2c2c);
  background: -moz-linear-gradient(top, #d64b4b, #ab2c2c);
  background: -ms-linear-gradient(top, #d64b4b, #ab2c2c);
}

.ribbon p {
  color: #fff;
  font-style: italic;
  margin: 0;
  padding: 0.25em;
  text-shadow: 0 1px 0 #d65c5c;
}

.ribbon:after,
.ribbon:before {
  border: 0.5em solid #c23a3a;
  bottom: -0.25em;
  content: "";
  display: block;
  position: absolute;
}

.ribbon:before {
  border-left-color: transparent;
  border-right-width: 0.25em;
  left: -0.5em;
}

.ribbon:after {
  border-left-width: 0.25em;
  border-right-color: transparent;
  right: -0.5em;
}

.ribbon .ribbon-content:after,
.ribbon .ribbon-content:before {
  border-color: #871616 transparent transparent;
  border-style: solid;
  bottom: -0.25em;
  content: "";
  display: block;
  position: absolute;
  z-index: 1;
}

.ribbon .ribbon-content:before {
  border-width: 0.25em 0 0 0.25em;
  left: 0;
}

.ribbon .ribbon-content:after {
  border-width: 0.25em 0.25em 0 0;
  right: 0;
}

#ccid {
  display: none;
}

.plusminus2 {
  margin: 2vw;
}

.datetext,
.inputs {
  //font-size: 3vh;
  line-height: 1.7em;
  margin: 0 auto;
  text-align: center;
  width: 75%;
}

.inputs {
  //border: 4px solid #000;
  display: block;
  min-height: 52px;
}

.datetext {
  display: inline-block;
  margin-bottom: 2px;
  margin-top: 2px;
}

.rmusertable,
.usertable,
.ultguesttable,
.photousertable,
tbody.user,
td.user,
tr.user {
  margin: 0 auto;
  text-align: center;
  width: 100%;
}

td.user {
  margin-bottom: 2px;
  margin-top: 2px;
}

.formtext {
  border-radius: 5px;
  font-size: calc(8px + 0.8vw);
  text-transform: uppercase;
  min-height: calc(8px + 0.8vw);
}

.form {
  width: 75%;
  margin: auto;
}

.checkbox {
  width: calc(12px + 0.4vw);
  height: calc(12px + 0.4vw);
}

.checkboxlabel {
  font-size: calc(12px + 0.4vw);
  line-height: 1.7em;
  display: inline;
}

.fv-help-block {
  color: #dc3545;
  font-size: 80%;
  margin-top: 0.25rem;
}

.center-modal {
  text-align: center;
  margin: auto;
}

#mainwaiverpassbody {
  margin: 2.5%;
}

.checkboxtext {
  font-size: calc(10px + 0.2vw);
}

.error {
  color: red;
  font-weight: 800;
}

canvas {
  width: 100%;
  height: 20%;
  border: 3px solid #ccc;
}

#guestform {
  max-width: 95%;
  margin: 2%;
}

.timestamp {
  font-size: 0.65vw;
  margin: 0 auto;
  margin-top: 2vh;
  text-align: center;
}

@media only screen and (max-width: 600px) {
  .form {
    width: 100%;
    margin: 0;
  }

  #mainwaiverpassbody {
    font-size: calc(15px + 3vw);
    padding: 8px;
  }
}

#passbodyicon {
  font-size: calc(15px + 5vw);
}

.rounded-lg {
  border-radius: 1rem !important;
}

.text-small {
  font-size: 0.9rem !important;
}

.custom-separator {
  height: 6px;
  border-radius: 1rem;
}

.text-uppercase {
  letter-spacing: 0.2em;
  text-transform: uppercase !important;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
  font-size: 1.25vw;
  overflow: hidden;
  word-break: normal;
  display: flex;
  flex-wrap: wrap;
}

.mb-3 {
  flex: 0 0 45%;
  padding: 5px;
  margin: auto;
}

.plannames {
  width: 100%;
}

.container {
  width: 90%;
  padding-right: 15px;
  padding-left: 15px;
  margin: 0 auto;
}

.amenity {
  font-size: 1.5rem;
}

@media (min-width: 576px) {
  .col-lg {
    flex: 1;
    max-width: 100%;
  }

  .col-lg-auto {
    flex: none;
    width: auto;
    max-width: 100%;
  }

  .col-lg-1 {
    flex-basis: 8.333333%;
    max-width: 8.333333%;
  }

  .col-lg-2 {
    flex-basis: 16.666667%;
    max-width: 16.666667%;
  }

  .col-lg-3 {
    flex-basis: 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex-basis: 33.333333%;
    max-width: 33.333333%;
  }

  .col-lg-5 {
    flex-basis: 41.666667%;
    max-width: 41.666667%;
  }

  .col-lg-6 {
    flex-basis: 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex-basis: 58.333333%;
    max-width: 58.333333%;
  }

  .col-lg-8 {
    flex-basis: 66.666667%;
    max-width: 66.666667%;
  }

  .col-lg-9 {
    flex-basis: 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex-basis: 83.333333%;
    max-width: 83.333333%;
  }

  .col-lg-11 {
    flex-basis: 91.666667%;
    max-width: 91.666667%;
  }

  .col-lg-12 {
    flex-basis: 100%;
    max-width: 100%;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .my-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }

  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .my-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }

  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .my-lg-3 {
    margin-top: 1rem !important;
  }

  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }

  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .my-lg-5 {
    margin-top: 3rem !important;
  }

  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }

  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }

  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  height: fit-content;
  --bs-gutter-x: 0;
}

.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  -ms-flex: 0 0 auto;
  flex: none;
  width: auto;
  max-width: 100%;
}

.col-1 {
  -ms-flex: 0 0 8.333333%;
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.col-2 {
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.col-5 {
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.col-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.col-8 {
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.col-9 {
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.col-11 {
  -ms-flex: 0 0 91.666667%;
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.col-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #00000014;
  border-radius: 0.25rem;
}

.card-body {
  -ms-flex: 1 1 auto;
  flex: auto;
  padding: 1.25rem;
}

.align-top {
  vertical-align: top !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary,
a.bg-primary:focus,
a.bg-primary:hover {
  background-color: red !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.d-block {
  display: block !important;
}

.align-items-end {
  -ms-flex-align: end !important;
  align-items: flex-end !important;
}

.align-items-center {
  -ms-flex-align: center !important;
  align-items: center !important;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.shadow {
  box-shadow: 0 0.5rem 1rem #00000026 !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem #0000002d !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.m-0 {
  margin: 0 !important;
}

.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}
.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 5 0 0 0 !important;
  border: 1px solid #000;
}

.m-auto {
  margin: auto !important;
}

.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.text-muted {
  color: #6c757d !important;
  text-decoration: line-through;
}
.video {
  display: block;
  margin: auto auto 5% auto;
}
.minerImage {
  height: auto;
  width: 3vw;
  position: fixed;
  right: 2vw;
  bottom: 2vw;
}
.cameraImage {
  height: auto;
  width: 3vw;
  float: right;
  position: block;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none;
}

.modal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: #00000066;
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}

.modal-footer {
  padding: 2px 16px;
  background-color: #fefefe;
  text-align: center;
  margin: 5px auto;
}

.action-btn {
  display: inline-block;
  padding: 10px 20px;
  background-color: #e83b4c;
  color: white;
  border-radius: 15px;
  font-size: 1.75rem;
}

.closebtn {
  color: #aaa;
  float: right;
  padding-right: 20px;
  font-weight: bold;
}
.backbtn {
  color: #aaa;
  float: left;
  padding-left: 20px;
  font-weight: bold;
}
.corporatedealsheader {
  font-weight: bold;
  text-align: center;
  margin: auto;
}
.fakebutton {
  display: inline-block;
  border-radius: 4px;
  padding: 8px 12px;
  text-transform: uppercase;
  color: #fff;
  background-color: #e83b4c;
  font-size: 1vw;
  font-weight: 600;
}
.logo {
  padding: 15px;
}
.iconcontainer {
  position: relative;
  border-radius: 50%;
  text-align: center;
}
.iconoverlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: #e83b4c;
  border-radius: 50%;
}
.iconcontainer:hover .iconoverlay {
  opacity: 1;
}
.icontext {
  color: white;
  text-transform: uppercase;
  font-size: 1vw;
  font-weight: 600;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
}

.member-form .form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  //font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #111;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #bdbdbd;
  text-transform: uppercase;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-size: 16px;
  height: 40px;
}
.member-form.recur-payment.terms .new-checkbox {
  margin-bottom: 0;
}
.member-form.recur-payment {
  margin-top: 49px;
}
.member-form {
  padding: 25px;
  border: 2px solid #424242;
}
.col-lg-12.form-group.save-btn {
  margin-top: 43px;
}
.wrap-collabsible input[type="checkbox"] {
  display: none;
}
.wrap-collabsible {
  position: fixed;
  box-sizing: border-box;
  bottom: 0;
  margin-right: auto;
  left: 0;
  width: auto;
}
.lbl-toggle {
  font-weight: bold;
  font-family: Gilroy, sans-serif;
  font-size: 1.2rem;
  text-transform: uppercase;
  text-align: center;
  padding: 1rem;
  color: #fff;
  background: #ab2c2c66;
  cursor: pointer;
  border-radius: 7px;
  transition: all 0.25s ease-out;
}
.lbl-toggle::before {
  content: " ";
  display: inline-block;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid currentColor;
  vertical-align: middle;
  margin-right: 0.7rem;
  transform: translateY(-2px);
  transition: transform 0.2s ease-out;
}
.toggle:checked + .lbl-toggle::before {
  transform: rotate(90deg) translateX(-3px);
}
.collapsible-content {
  max-height: 0px;
  overflow: auto;
  transition: max-height 0.25s ease-in-out;
}
.collapsible-content::-webkit-scrollbar {
  display: none;
}
.toggle:checked + .lbl-toggle + .collapsible-content {
  max-height: 100vh;
}
.toggle:checked + .lbl-toggle {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.collapsible-content .content-inner {
  background: #ab2c2cff;
  border-bottom: 1px solid #ab2c2cff;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  padding: 0.5rem 1rem;
}
.collapsible-content p {
  margin-bottom: 0;
}

.member-form {
  padding: 25px;
  border: 2px solid #424242;
}

.form-control {
  border-radius: 0;
  font-size: 0.9rem;
}
.input-group {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
}

.input-group > .form-control {
  position: relative;
  -ms-flex: 1 1 0%;
  flex: 1 1 0%;
  min-width: 0;
  margin-bottom: 0;
}

.input-group > .form-control + .form-control {
  margin-left: -1px;
}

.input-group > .form-control:focus {
  z-index: 3;
}

.input-group > .form-control:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .form-control:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-text {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.input-group-text input[type="checkbox"],
.input-group-text input[type="radio"] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea) {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control:not(textarea) {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-control {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[multiple],
select.form-control[size] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

.form-check-input:disabled ~ .form-check-label,
.form-check-input[disabled] ~ .form-check-label {
  color: #6c757d;
}
button:disabled {
  background-color: gray;
  color: gray;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.form-inline {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -ms-flex-align: center;
  align-items: center;
}

.form-inline .form-check {
  width: 100%;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: none;
  overflow: auto;
  background-color: #000000;
  background-color: #000000b2;
  z-index: 9999;
}

.modal-window {
  position: relative;
  background-color: #ffffff;
  width: 50%;
  margin: 10% auto;
  padding: 20px;
}

.modal-window.small {
  width: 75%;
}

.modal-window.large {
  width: 75%;
}
.modal-title {
  line-height: 1.5;
  text-align: center !important;
  margin: 0 auto;
  width: 100%;
}

.close {
  position: absolute;
  top: 0;
  right: 0;
  color: #000000b2;
  height: 30px;
  width: 30px;
  font-size: 30px;
  line-height: 30px;
  text-align: center;
}

.close:hover,
.close:focus {
  color: #000000;
  cursor: pointer;
}

.open {
  display: block;
}

.duebtn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
#onlineSignupEmail * {
  font-family: Gilroy, sans-serif;
}

#onlineSignupEmail img {
  max-width: 100%;
}

.collapse {
  margin: 0;
  padding: 0;
}

#onlineSignupEmail .white {
  background-color: #ffffff;
}

#onlineSignupEmail a {
  color: #2ba6cb;
}

#onlineSignupEmail .btn {
  text-decoration: none;
  color: #fff;
  background-color: #666;
  padding: 10px 16px;
  font-weight: bold;
  margin-right: 10px;
  text-align: center;
  cursor: pointer;
  display: inline-block;
}

#onlineSignupEmail p.callout {
  padding: 15px;
  background-color: #ecf8ff;
  margin-bottom: 15px;
}

#onlineSignupEmail .callout a {
  font-weight: bold;
  color: #2ba6cb;
}

#onlineSignupEmail table.social {
  background-color: #ebebeb;
}

#onlineSignupEmail table.branded {
  background-color: #999999;
}

#onlineSignupEmail .social .soc_btn {
  padding: 3px 7px;
  font-size: 12px;
  margin-bottom: 10px;
  text-decoration: underline;
  color: #2ba6cb;
  font-weight: bold;
  display: block;
  text-align: center;
}

#onlineSignupEmail a.fb {
  background-color: #3b5998 !important;
}

#onlineSignupEmail a.tw {
  background-color: #7ebae4 !important;
}

#onlineSignupEmail a.gp {
  background-color: #db4a39 !important;
}

#onlineSignupEmail a.ms {
  background-color: #000 !important;
}

#onlineSignupEmail .sidebar .soc_btn {
  display: block;
  width: 100%;
}

#onlineSignupEmail table.right {
  float: right;
}

#onlineSignupEmail table.left {
  float: left;
}

#onlineSignupEmail table.center {
  margin-left: auto;
  margin-right: auto;
}

#onlineSignupEmail tr.right,
#onlineSignupEmail td.right {
  text-align: right;
}

#onlineSignupEmail tr.left,
#onlineSignupEmail td.left {
  text-align: left;
}

#onlineSignupEmail tr.center,
#onlineSignupEmail td.left {
  text-align: center;
}

#onlineSignupEmail table.head_wrap {
  width: 100%;
}

#onlineSignupEmail .header.container table td.logo {
  padding: 15px;
}

#onlineSignupEmail .header.container table td.label {
  padding: 15px;
  padding-left: 0px;
}

#onlineSignupEmail table.body_wrap {
  width: 100%;
}

#onlineSignupEmail table.footer_wrap {
  width: 100%;
  clear: both !important;
}

#onlineSignupEmail .footer_wrap .container td.content p {
  border-top: 1px solid #d7d7d7;
  padding-top: 15px;
}

#onlineSignupEmail .footer_wrap .container td.content p {
  font-size: 10px;
  font-weight: bold;
}

#onlineSignupEmail h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Gilroy, sans-serif;
  line-height: 1.1;
  margin-bottom: 15px;
  color: #000;
}

#onlineSignupEmail h1 small,
#onlineSignupEmail h2 small,
#onlineSignupEmail h3 small,
#onlineSignupEmail h4 small,
#onlineSignupEmail h5 small,
#onlineSignupEmail h6 small {
  font-size: 60%;
  color: #6f6f6f;
  line-height: 0;
  text-transform: none;
}

#onlineSignupEmail h1 {
  font-weight: 200;
  font-size: 44px;
}

#onlineSignupEmail h2 {
  font-weight: 200;
  font-size: 37px;
}

#onlineSignupEmail h3 {
  font-weight: 500;
  font-size: 27px;
}

#onlineSignupEmail h4 {
  font-weight: 500;
  font-size: 23px;
}

#onlineSignupEmail h5 {
  font-weight: 900;
  font-size: 17px;
}

#onlineSignupEmail h6 {
  font-weight: 900;
  font-size: 14px;
  text-transform: uppercase;
  color: #444;
}

#onlineSignupEmail .collapse {
  margin: 0 !important;
}

#onlineSignupEmail p,
#onlineSignupEmail ul {
  margin-bottom: 10px;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.6;
}

#onlineSignupEmail p.lead {
  font-size: 17px;
}

#onlineSignupEmail p.last {
  margin-bottom: 0px;
}

#onlineSignupEmail ul li {
  margin-left: 5px;
  list-style-position: inside;
}

ol li {
  margin-left: 10px;
  list-style-position: inside;
  font-size: 14px;
}

#onlineSignupEmail ul.sidebar {
  background: #ebebeb;
  display: block;
  list-style-type: none;
}

#onlineSignupEmail ul.sidebar li {
  display: block;
  margin: 0;
}

#onlineSignupEmail ul.sidebar li a {
  text-decoration: none;
  color: #666;
  padding: 10px 16px;
  margin-right: 10px;
  cursor: pointer;
  border-bottom: 1px solid #777777;
  border-top: 1px solid #ffffff;
  display: block;
  margin: 0;
}

#onlineSignupEmail ul.sidebar li a.last {
  border-bottom-width: 0px;
}

#onlineSignupEmail ul.sidebar li a h1,
#onlineSignupEmail ul.sidebar li a h2,
#onlineSignupEmail ul.sidebar li a h3,
#onlineSignupEmail ul.sidebar li a h4,
#onlineSignupEmail ul.sidebar li a h5,
#onlineSignupEmail ul.sidebar li a h6,
#onlineSignupEmail ul.sidebar li a p {
  margin-bottom: 0 !important;
}

#onlineSignupEmail .container {
  display: block !important;
  max-width: 600px !important;
  margin: 0 auto !important;
  /* makes it centered */
  clear: both !important;
}

#onlineSignupEmail .content {
  padding: 15px;
  max-width: 600px;
  margin: 0 auto;
  display: block;
}

#onlineSignupEmail .content table {
  width: 100%;
}

#onlineSignupEmail .column {
  width: 300px;
  float: left;
}

#onlineSignupEmail .column tr td {
  padding: 15px;
}

#onlineSignupEmail .column_wrap {
  padding: 0 !important;
  margin: 0 auto;
  max-width: 600px !important;
}

#onlineSignupEmail .column table {
  width: 100%;
}

#onlineSignupEmail .social .column {
  width: 280px;
  min-width: 279px;
  float: left;
}

/* Custom CSS add-ons - Mike T., 1/21/2013 */
#onlineSignupEmail hr {
  clear: both;
}

#onlineSignupEmail .small_span {
  float: left;
  width: 120px;
}

#onlineSignupEmail .medium_span {
  float: left;
  width: 160px;
}

#onlineSignupEmail .large_span {
  float: left;
  width: 200px;
}

#onlineSignupEmail table.responsive th {
  font-weight: bold;
}

#onlineSignupEmail table.responsive td,
table th {
  text-align: left;
}

#onlineSignupEmail table.responsive td.money,
table th.moneyheader {
  text-align: right;
}

#onlineSignupEmail table.responsive td.money {
  padding-right: 4px;
}

#onlineSignupEmail table.responsive th {
  padding: 4px 5px;
}

/* Custom CSS for button - Mike T., 1/30/2013 */
#onlineSignupEmail .emailButton {
  background: #00a6fc;
  display: inline-block;
  text-align: center;
  padding: 9px 34px 11px;
  color: #fff;
  text-decoration: none;
  font-weight: bold;
  line-height: 1;
  position: relative;
  cursor: pointer;
}

#onlineSignupEmail .emailButton.nice {
  background: #00a6fc repeat-x 0 -34px;
  -moz-box-shadow: inset 0 1px 0 #ffffff80;
  -webkit-box-shadow: inset 0 1px 0 #ffffff80;
  text-shadow: 0 -1px 1px #00474747;
  background: #00a6fc repeat-x 0 -34px,
    -moz-linear-gradient(top, #ffffff66 0%, transparent 100%);
  background: #00a6fc repeat-x 0 -34px,
    -webkit-gradient(linear, left top, left bottom, color-stop(0%, #ffffff66), color-stop(100%, transparent));
  border: 1px solid #0593dc;
  -webkit-transition: background-color 0.15s ease-in-out;
  -moz-transition: background-color 0.15s ease-in-out;
  -o-transition: background-color 0.15s ease-in-out;
}

#onlineSignupEmail .emailButton.radius {
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}

#onlineSignupEmail .blue.emailButton {
  background-color: #00a6fc;
}

#onlineSignupEmail .nice.blue.emailButton {
  border: 1px solid #0593dc;
}

#onlineSignupEmail .emailButton:hover {
  background-color: #0192dd;
  color: #fff;
}
/* Be sure to place a .clear element after each set of columns, just to be safe */
#onlineSignupEmail .clear {
  display: block;
  clear: both;
}
#status-indicator {
  position: static;
  top: 10px;
  left: 10px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 3px solid #333;
  box-shadow: inset 0 0 5px #00000033, 0 0 5px #00000033;
}

.online {
  background-color: #00ff00; /* bright green */
  border-color: #00ff00;
  box-shadow: inset 0 0 5px #00ff0066, 0 0 5px #00ff0066;
}

.offline {
  background-color: #ff3333; /* bright red */
  border-color: #ff3333;
  box-shadow: inset 0 0 5px #ff000066, 0 0 5px #ff000066;
}

.apiDown {
  background-color: #ffa500; /* orange */
  border-color: #ffa500;
  box-shadow: inset 0 0 5px #ffa50066, 0 0 5px #ffa50066;
}

.holds-the-iframe {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

#iframe {
  flex-grow: 1;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  border: none;
  background-color: #fff;
  overflow-x: hidden;
  z-index: 901;
  overscroll-behavior: contain;
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll;
  touch-action: none; /* Allow vertical scrolling only */
}

.menu-item {
  display: inline-block;
  margin: auto;
  padding-right: 2vw;
  padding-left: 2vw;
  width: auto;
  color: #333;
  vertical-align: middle;
  text-decoration: none;
  border: 2px solid #000;
  background-color: transparent;
  outline: none;
  font-family: Arial, sans-serif;
}

.menu-item:focus {
  background-color: #dadaf0;
  box-shadow: 0 0 0 2px #333;
}

.menu-container {
  position: relative;
  display: flex;
  align-items: center;
  background-color: #f1f1f1;
  border: 2px solid #000;
}

.iFrameContainer {
  position: relative;
  background-color: #f1f1f1;
  white-space: nowrap;
  overflow-x: hidden;
  padding-right: 70px;
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll;
}

.scroll-arrows {
  height: 100%;
  max-width: 50px;
  text-align: center;
  line-height: 4vw;
  font-size: 3.5vw;
  font-weight: bold;
  color: #000;
  transition: background-color 0.2s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
}

.scroll-arrow-left {
  margin-right: 20px;
  padding-left: 20px;
}

.scroll-arrow-right {
  margin-left: 20px;
  padding-right: 20px;
}

.white-background {
  background-color: #f1f1f1;
  width: 100%;
}

.dropbtn {
  background-color: #ee3124;
  color: white;
  padding: 16px;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #ee3124;
  box-shadow: 0px 8px 16px 0px #00000033;
  z-index: 1;
}

.dropdown-content a {
  display: block;
  padding: 16px;
}

.show {
  display: block;
}

.slick-slide {
  height: auto;
}

.input-container {
  position: relative;
  display: flex;
  align-items: center;
}

.barcode-icon,
.camera-icon,
.dl-icon {
  padding: 5px;
  height: 100%;
  width: 5vw;
}

.barcodeScannerWindow {
  margin: auto;
  width: 60%;
  overflow: hidden;
}

#newMemberCameraIconWindow {
  position: relative;
  margin: auto;
  width: 60%;
  overflow: hidden;
}

#cameraView {
  width: 100%;
  height: 100%;
}

.h2,
.text1 h1,
.text2 h1 {
  font-weight: bold;
  display: block;
  width: 100%;
  max-width: 100%;
  text-align: center;
  margin: 0 auto; /* Center horizontally */
}

h2 {
  margin-top: 0.83em;
}

.text1 span,
.text2 span {
  font-size: 80%;
}

@media (min-width: 576px) {
  .h2,
  .text1 h1,
  .text2 h1 {
    font-size: calc(1.5vw + 1rem);
  }
}

@media (min-width: 768px) {
  .h2,
  .text1 h1,
  .text2 h1 {
    font-size: calc(1.25vw + 1rem);
  }
}

@media (min-width: 992px) {
  .h2,
  .text1 h1,
  .text2 h1 {
    font-size: calc(1vw + 1rem);
  }
}

@media (min-width: 1200px) {
  .h2,
  .text1 h1,
  .text2 h1 {
    font-size: calc(0.75vw + 1rem);
  }
}

.pricingContainer {
  padding: 1rem !important;
}
.no-wrap {
  white-space: nowrap;
}

.required {
  position: relative;
}

.required::after {
  content: "*";
  right: 5px;
  color: red;
}

.perMonth {
  position: relative;
}

.perMonth::before {
  content: "/ ";
}

.idmebutton {
  width: 15%;
  height: auto;
}

.errorMessage {
  font-size: 14px;
  padding-bottom: 10%;
}

.flatpickr-calendar {
  max-width: 320px; /* Limit the width to typical mobile screen width */
  font-size: 16px; /* Adjust font size if necessary */
}
.flatpickr-calendar.inline {
  display: inline-block;
  width: 100%;
}
.flatpickr-calendar.hasTime .flatpickr-time {
  border-top: 1px solid #ddd;
}
.flatpickr-time {
  display: flex;
  justify-content: space-between;
  padding: 5px;
}
.flatpickr-time .numInputWrapper {
  flex: 1;
  margin: 0 5px;
}
.flatpickr-time .flatpickr-time-separator {
  line-height: 2.6;
  padding: 0 5px;
}

.form-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.dl-icon {
  width: 5vw; /* Adjust the size as needed */
  position: absolute;
  top: 10px;
  right: 10px;
}

@keyframes flash {
  0%, 100% { 
    color: black; 
    background-color: white; 
  }
  50% { 
    color: white; 
    background-color: black; 
  }
}

.flash-once {
  animation: flash 0.5s linear 1;
}

.dollar::before {
  content: '$';
  position: relative;
  vertical-align: super;
  font-size: 0.5em;
  margin-right: 2px;
}

.text-muted {
  text-decoration: none;
  font-size: 0.8em;
}

@keyframes dce-scanlight {
  0% { top: 0; }
  50% { top: 100%; transform: translateY(-100%); }
  100% { top: 0; }
}

.dce-scanlight {
  position: absolute;
  width: 100%;
  height: 3%; /* Adjust height as needed */
  border-radius: 50%;
  box-shadow: 0px 0px 2vw 1px rgb(0, 229, 255);
  background-color: rgb(255, 255, 255);
  animation: dce-scanlight 3s ease infinite;
 // user-select: none;
  --webkit-user-select: none;
  display: block;
}

.infoIcon {
  max-width: 5%;
  display: inline;
}

#scanningModalWindow video {
  height: auto;
  width:100%;
}

#scanningModalWindow .drawingBuffer {
 position: absolute;
  left: 0;
border: #000 0px;
pointer-events: none;
height: auto;
}